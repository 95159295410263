@import '../../base/mixins';

.container input, .check-terms {
    position: static;
    opacity: initial;
    cursor: pointer;
    width: 300px;
    height: 50px;
}
.input-container select {
    word-wrap: normal;
    width: 300px !important;
}
.landingb2b__h3-box{
    font-size: 10px;
    color: $greenb2b;
    margin-top: 0;
    margin-bottom: 0.6rem;
    line-height: 130%;
    display: block;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    font-weight: 900;
}  
input.form-check-input-b2bform.auth__form-check-b2bform {
    width: 20px;
    height: 20px;
    position: inherit;
    opacity: 1;
}
input.form-check-input-b2bform.auth__form-check-b2bform:checked ~ input {
    background-color: green; border: none;
  }
    .landingb2b__schedule-consulting a{
        color: $black;
    }
@media (max-width: 767px) {
 

    .landingb2b__container-schedule-consulting{
        background-image: url('../../../assets/images/landingB2B/BannerScheduleConsultingMobile.png');
        min-height: 375px;
        background-size: cover;
        position: relative;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: end;
        justify-content: center;
    }
    .img-fluid {
        height: auto;
    }
}    

.landingb2b__schedule-consulting{
    width: 300px;
    background-color: $primaryb2b;
    border-radius: 25px;
    color: $black;
    font-size: 16px;
    font-weight: 900;
    padding: 18px;
    cursor: pointer;
}
@media (min-width: 620px) {
   
    .landingb2b__container-schedule-consulting{
        background-image: url('../../../assets/images/landingB2B/BannerScheduleConsulting.png');
        min-height: 475px;
        background-size: cover;
        position: relative;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: end;
        justify-content: center;
    }
    .landingb2b__schedule-consulting{
        width: 400px;
        background-color: $primaryb2b;
        border-radius: 25px;
        color: $black;
        font-size: 18px;
        font-weight: 900;
        padding: 18px;
        cursor: pointer;
    }
    .img-fluid {
        height: auto;
        align-items: center;
    }
}    

.header-background {
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}
.landing__HomeServicesSection{
    margin: 75px 0 0 0;
    position: relative;
    height: auto;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.landing__HomeSection{
    margin: 118px 0 0 0;
    background-color: $greenb2b;
    position: relative;
    height: auto;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenedor {
    position: relative;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing__circle {
    width: 60px;
    height: 60px;
    background-color: $greenb2b;
    border-radius: 50%;
    position: relative;
    top: -5px;
    cursor: pointer;
}

.landing_chevron {
    position: absolute;
    top: 15%;
    left: 32%;
    font-size: 25px;
    color: $primaryb2b;
}
.landing__bgservices{
    background-color: #E0E0E0;
}
.landing__bestoptions{
    background-color: $black;
}

.landingb2b__button-primary{
    //font-family: 'Open Sans';
    background-color: $primaryb2b;
    border-radius: 18px;
    color: $black;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    padding: 10px 20px;
    cursor: pointer;
}
.landingb2b__button-primary:hover{
  color: $white;
}

.landing__container{
    margin-right: auto;
    margin-left: auto;
}    

.landingb2b__h3{
    font-family: 'Open Sans';
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0.6rem;
    line-height: 130%;
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 900;
}    

.landingb2b__servicios-eanx-b2b{
    height: 200px;
    width: 180px;
    margin: 10px;
}
.landingb2b__servicios-eanx-b2b:hover{
  -webkit-transform:scale(1.25);
  -moz-transform:scale(1.25);
  -ms-transform:scale(1.25);
  -o-transform:scale(1.25);
  transform:scale(1.25);
}

.landingb2b__paragraph-text{
    font-size: 18px;
    line-height: 160%;
    font-family: 'Open Sans';
}
.landingb2b__text-respaldo-ean{
    margin-top: 60px;
    font-size: 10px;
    line-height: 160%;
    font-family: $font-montserrat;
    font-weight: 600;
    font-style: italic;
}  
.landingb2b__white{
  color: $white;
}  
.landingb2b__button-primary:hover{
  color: $white;
}
.landingb2b__button-primary:hover{
  color: $white;
}  

.landingb2b__paragraph{
    font-family: 'Open Sans';
    color: $black;
    font-size: 15px;
    font-weight: 400;
    padding: 0 15px;
}    

.landing__section-line{
    color: $light-green;
    border: 3px solid $light-green;
    width: 90px;
    opacity: 100%!important;
    margin: auto;
    margin-top: 5rem;
}    

.landing__title-servicios-b2b{
    color: $black;
    font-size: 18px;
    font-weight: 900;    
    width: 150px;
    height: 50px;
    margin: 10px auto;
    line-height: 20px;
    font-family: 'Open Sans';
}    


.landing__text-color-white{
    color: $white;
}
.landing__footer-1{
    background-color: #2E2E2E;
}

.landing__footer-2{
    background-color: $black;
}


.landing__text-color-green{
    color: $primary;
}
.landing__text-color-dark-grey{
    color: $dark-grey;
}
.border-left{
    border-right: 2px solid $buttom-graduados;
}
.landingb2b__title-top-banner{
    font-family: 'Open Sans';
    color: $white;
    font-weight: 800;
    font-size: 46px;
    text-align: left;
    margin: 14px 0;
    line-height: 48px;
}
.landingb2b__title-thanks{
    font-family: 'Open Sans';
    color: $white;
    font-weight: 800;
    font-size: 62px;
    text-align: left;
    margin: 14px 0;
    line-height: 70px;
}
.landingb2b__paragraph-text-thanks{
    font-size: 26px;
    line-height: 160%;
    font-family: 'Open Sans';
}
.title-one-conoce-eanx{
    color: $dark-greyb2b;    
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 60px;
    line-height: 40px;
    padding: 10px 0;
}
.title-two-conoce-eanx{
    color: $dark-greyb2b;
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 30px;
}
.text-conoce-eanx{
    font-size: 20px;
    font-family: 'Open Sans';
    color: $dark-greyb2b;
    max-width: 195px;
    line-height: 22px;
}
.landing__FormSection{
    margin: 118px 0 0 0;
    background-color: $greenb2b;
    position: relative;
    height: auto;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.landing__FormContent{
    background-color: $white;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my-sm-2, .mb-sm-2 {
    margin-bottom: 0.5rem!important;
}
.my-sm-2, .mt-sm-2 {
    margin-top: 0.5rem!important;
}
.mx-sm-2 {
    margin-top: 0.5rem!important;    
    margin-bottom: 0.5rem!important;
}
.py-sm-2, .pt-sm-2 {
    padding-top: 0.5rem!important;
}
.py-sm-2, .pb-sm-2 {
    padding-bottom: 0.5rem!important;
}
.px-sm-2 {
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
}
.py-sm-2 {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
}
.input-container {
    position: relative;
}

.input-container-input::placeholder {
    color: $secondaryb2b;
}
.input-container-input {
    color: black;
    font-weight: bold;
    width: 320px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    outline: 2px solid $white;
    border-bottom: $secondaryb2b 2px solid;
    background-color: transparent;
    transition: outline-color 500ms;
}

.input-container-input:is(:focus, :valid) {
    outline-color: $secondaryb2b;
}

.input-container-label {
    position: absolute;
    top: 0;
    left: 0;
    translate: 18px 0;
    color: $secondaryb2b;
    font-weight: bold;
    transition: translate 500ms, scale 500ms;
}
.input-container-label-aux {
    position: absolute;
    top: 0;
    left: 0;
    translate: 18px 0;
    color: $secondaryb2b;
    font-weight: bold;
    transition: translate 500ms, scale 500ms;
}

.input-container-input:focus + .input-container-label,
.input-container-input:valid + .input-container-label,
.input-container-input:focus + .input-container-label-aux,
.input-container-input:valid + .input-container-label-aux {
    padding-inline: 10px;
    translate: 0px -19px;
    scale: 0.8;
    background-color: $white;
    font-size: 20px;
    font-weight: bold;
}
  .wrapper {
    display: inline-block;
    margin-right: 20px;
    width: 200px;
  }
@media (max-width: 769px) {
    .landingb2b__title-thanks{
        font-size: 48px;
        line-height: 50px;
    }
    .landingb2b__paragraph-text-thanks{
        font-size: 22px;
        line-height: 160%;
    }
    .landingb2b__h3{
        font-size: 18px;
    }
    .container input, .check-terms, .alert-erros-b2b {
        width: 250px;
    }
    .input-container select {
        width: 250px !important;
    }
}

@media (min-width: 1400px){
    .container input, .check-terms, .alert-erros-b2b {
        width: 400px;
    }
    .input-container select {
        word-wrap: normal;
        width: 400px !important;
    }
    .landingb2b__h3{
        font-size: 26px;
    }
    .landingb2b__container-schedule-consulting{
        background-image: url('../../../assets/images/landingB2B/BannerScheduleConsulting.png');
        min-height: 575px;
        background-size: cover;
        position: relative;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: end;
        justify-content: center;
    }   
}   