.UserAdmin__button-container{
  align-items: center;
  background-color: $white;
  border: solid 1px $dark-grey;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  margin: 0px 5px;
  width: 28px;
}

.UserAdmin__color-primary{
  color: $primary;
}
.B2B__modal-base{
  background-color: $white;
  border-radius: 10px;
  font-weight: 600;
  left: 50%;
  line-height: 120%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 18px;
  width: 600px;
  @media (max-width: 576px){
    font-size: 18px;
    width: 80%;
  }
}

.B2B__ActionButton {
  &:hover, &:visited, &:focus, &:focus-visible {
      color: $white;
      border: 2px solid $primary;
  }
  &:focus-visible, &:focus  {
      outline: 0; 
  }
  background-color: $white;
  color: $primary;
  border-radius: 25px;
  border: 2px solid $primary;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  width: 100%;
  border-radius: 99px;
  height: 42px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  text-align: center;
}

.ActionButton--primary {
  &:hover, &:visited {
      color: $white;
  }
  background-color: $primary;
  color: $white;
}
.ActionButton--secondary {
  &:hover, &:visited {
      color: $primary;
  }
  background-color: $white;
  color: $primary;
  margin-top: 10px;
  width: 100%;
  border-radius: 99px;
  height: 42px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  text-align: center;
}

.UserAdmin__color-secondary{
  color: $secondary;
}

.UserAdmin__create-button{
  background-color: $primary;
  color: $white;
  align-items: center;
  padding: 5px 20px;
  gap: 10px;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 99px;
  &:hover {
    color: $white;
  }
}
.UserAdmin__input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  font-style: italic;
  color: $dark-grey;
}
.UserAdmin__input:focus{
  @include input_focus
}
.UserAdmin__link  {
  &:hover {
    text-decoration: underline;
    color: $primary;
  }
  text-decoration: none;
  color: $primary;
}

.UserAdmin__link  {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
}



.UserAdmin__input-phone {
  height: auto !important;
  width: 100% !important;
  //padding: 0.375rem 0.75rem !important;
  padding: 0.375rem 0.75rem 0.375rem 58px !important;
  font-size: 1rem !important;
}

.UserAdmin__input-phone:focus{
  outline: 1px solid $primary !important;
  border: 1px solid $primary !important;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}
.UserAdmin__select:focus{
  outline: 1px solid $primary !important;
  border: 1px solid $primary !important;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}
.UserAdmin__codes{
  text-align: center;
}

.UserAdmin__input-code {
  width: 36px;
  height: 52px;
  border-radius: 8px;
  margin: 7px;
  display: initial;
}
.UserAdmin__input-code:focus{
  outline: 1px solid $primary;
  border: 1px solid $primary;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.UserAdmin__input--error {
  border-color: $red;
}

.UserAdmin__input--ok {
  border-color: $primary;
}
.UserAdmin__inputError {
  color: $red;
}

.UserAdmin__errorMessage {
  color: $red;
  min-height: 1.5em;
}
.UserAdmin__select:focus{
  outline: 1px solid $primary !important;
  border: 1px solid $primary !important;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}
.UserAdmin__input--error {
  border-color: $red;
}

.UserAdmin__find-user-container{
  display: flex;
  justify-content: end;

  width: 794px;
  @include desktop-min{
    width: 90%;
  }
}

.UserAdmin__input{
  border: 1px solid $dark-grey;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  height: 35px;
  max-height: 35px;
  width: 150px;
  @include tablet-min{
    width: 380px;
  }
  @include desktop-min{
    width: 420px;
  }
}
.UserAdmin__input:focus{
  outline: 1px solid $primary;
  border: 1px solid $primary;
}

.UserAdmin__modal-base{
  background-color: $white;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 600;
  left: 50%;
  line-height: 120%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 300px;
  @include big-phone-min{
    font-size: 28px;
    width: 350px;
  }
}

.UserAdmin__modal-buttons-container{

  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.UserAdmin__modal-button-delete{
  background-color: $secondary;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 99px;
  color: $white;
  margin-bottom: 16px;
  width: 83.33%;


  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;


}
.UserAdmin__modal-button-cancel{
  background-color: $white;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 99px;
  color: $secondary;
  margin-bottom: 32px;
  width: 83.33%;


  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid $secondary;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  // transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.UserAdmin__modal-container{
  display: flex;
  justify-content: center;
  margin-bottom: 38px;
  margin-top: 42px;
  text-align: center;
}

.UserAdmin__table{
  border: 1px solid $dark-grey;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0px;
  overflow: hidden;
  width: 100%;
}

.UserAdmin__table-header{
  background-color: $primary;
  border-radius: 8px 8px 0px 0px;
  color: $white;

}

.UserAdmin__subtable-header{
  background-color: #4f4c4c;
  border-radius: 8px 8px 0px 0px;
  color: $white;

}

.UserAdmin__table-row{
  height: 52px;
}

.UserAdmin__table-pair-row{
  background-color: $light-grey;
}

.UserAdmin__table-odd-row{
  background-color: $white;
}

.UserAdmin__table-td {
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.UserAdmin__activate::before{
  content: "\2022";
  color: $primary;
  font-weight: bold; /* If you want it to be bold */
  font-size: 20px;
  display: inline-block;
  width: 1em;
}

.UserAdmin__inactivate::before{
  content: "\2022";
  color: $red;
  font-weight: bold; /* If you want it to be bold */
  font-size: 20px;
  display: inline-block;
  width: 1em;
}

.UserAdmin__outline-none:focus-visible{
  outline: none!important;
}


.B2BAdmin__buttons-group{
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  gap: 0.5em;
}
.B2BAdmin__text-description{
  color: $black;
  font-size: 0.8rem;
  font-weight: 600;
}
.B2BAdmin__framecourses{
  border: 1px solid $dark-grey;
  border-collapse: separate;
  border-spacing: 0px;
  overflow: hidden;
  width: 100%;
  max-height: 350px;
  margin-top: 17px;
  overflow-y: auto;
  padding: 10px;
}
.B2BAdmin__divcourse {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}
.B2BAdmin__delete-button{
  color: $red;
  align-items: center;
  font-size: 12px;
  border: none;
  &:hover {
    color: #6d0f0f;
  }
}
.B2BAdmin__link-to-csv{
  color: $primary;
  &:hover {
      color: $secondary;
      text-decoration: underline;
  }
}

.overflowr {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: auto;
  color: #FFF;
  font-size: 14px;
}
.overflowm {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: auto;
}
.h2report{
  color: white !important;  
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5em;  
  @media only screen and (min-width: 1366px) {
      font-size: 2.2em;  
  }
  @media only screen and (max-width: 1366px) {
      font-size: 1.5em;  
  }
}
.h2hour{
  font-size: 2.5em;    
  font-weight: 500;
  line-height: 1.2;
}
.rounded-left {
  border-top-left-radius: 0.25rem!important;
}
.rounded-bottom, .rounded-left {
  border-bottom-left-radius: 0.25rem!important;
}
.rounded-bottom, .rounded-right {
  border-bottom-right-radius: 0.25rem!important;
}
.rounded-right, .rounded-top {
  border-top-right-radius: 0.25rem!important;
}
.icons {
  font-size: 40px;
}
.cardReport {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}
.bg-primary-dark {
  background-color: #2f80e7;
  color: #fff!important;
}
.bg-primary {
  background-color: #5d9cec;
  color: #fff!important;
}
.bg-purple-dark {
  background-color: #564aa3;
  color: #fff!important;
}
.bg-purple {
  background-color: #7266ba;
  color: #fff!important;
}
.bg-green-dark {
  background-color: #2b957a;
  color: #fff!important;
}
.bg-green {
  background-color: #37bc9b;
  color: #fff!important;
}
.bg-success-dark {
  background-color: #1e983b;
  color: #fff!important;
}
.bg-success {
  background-color: #27c24c!important;
}